import { StoreHours } from "~/bff/types/StoreHours";

type Day = keyof StoreHours;

export const isDay = (key: string): key is Day => {
  return [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ].includes(key);
};
