"use client";

import React, { MouseEvent, useCallback, useMemo, useRef, useState } from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { City } from "~/bff/types/City";
import { StoreLocatorPageTranslations } from "~/bff/types/StoreLocatorPageTranslations";
import { CombinedButton } from "~/components/combined-button/component";
import { DEBOUNCE_DELAY_300 } from "~/constants/delay-constants";
import { Routes, Variables } from "~/constants/request";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { encodeStoreAddress } from "~/helpers/encode-store-address";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useWindowEvent } from "~/hooks/use-window-event/index";
import { Nullable, Optional } from "~/types/general.types";

import { CITIES_LIST_CLASSES } from "./constants";
import { BackdropStyled, CitiesListContainer } from "./styled";

export interface CitiesListProps {
  cities?: Nullable<Nullable<City>[]> | undefined;
  translations?: Nullable<StoreLocatorPageTranslations> | undefined;
}

export const CitiesList: React.FC<CitiesListProps> = ({ cities, translations }) => {
  const [listTop, setListTop] = useState<Optional<number>>();
  const [listTopCached] = useDebounce(listTop, DEBOUNCE_DELAY_300);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const countryName = useVariable<string>(Variables.COUNTRY_NAME);
  const prepared = useMemo(
    () =>
      cities?.sort((a: Nullable<City>, b: Nullable<City>) => {
        if (!a || !b) {
          return 0;
        }
        return a.name > b.name ? 1 : -1;
      }) ?? [],
    [cities],
  );

  const gropedByLatter = useMemo(() => {
    let prevLetter;
    const result: City[][] = [];
    for (const city of prepared) {
      if (!city) {
        continue;
      }
      if (!city?.name.startsWith(prevLetter ?? " ")) {
        prevLetter = city.name[0];
        result.push([]);
      }
      result[result.length - 1].push(city);
    }
    return result;
  }, [prepared]);

  const alphabet = useMemo(
    () =>
      Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ").map((letter) => ({
        enabled:
          prepared.find((city) => city?.name.startsWith(letter)) !== undefined,
        letter: letter,
      })),
    [prepared],
  );

  const handleMove = useCallback((event: MouseEvent<HTMLDivElement>) => {
    const letter = event.currentTarget.dataset.nav;
    if (letter) {
      rootRef.current
        ?.querySelector(`[data-letter=${letter}]`)
        ?.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
    }
  }, []);

  const handleProgress = useCallback(() => setInProgress(true), []);

  const debouncedScrollHandler = useDebouncedCallback(() => {
    const { top: alphabetTop } = rootRef.current?.getBoundingClientRect() || {};
    setListTop(alphabetTop);
  }, DEBOUNCE_DELAY_300);

  useWindowEvent("scroll", debouncedScrollHandler);

  return (
    <>
      <BackdropStyled open={inProgress}>
        <CircularProgress color="inherit" />
      </BackdropStyled>

      <CitiesListContainer
        ref={rootRef}
        listTop={listTopCached}
        {...getTestAutomationProps("cities-list")}
      >
        <Typography
          component="h5"
          variant="h5"
          className={CITIES_LIST_CLASSES.title}
        >
          {`${translations?.storeListTitle} ${countryName}`}
        </Typography>
        {gropedByLatter.map((preparedCities, index) => {
          const letter = preparedCities[0].name[0];
          return (
            <Box key={index}>
              <Typography
                data-letter={letter}
                className={CITIES_LIST_CLASSES.letter}
              >
                {letter}
              </Typography>
              {preparedCities.map((city) => {
                const parameter = city.name.toLowerCase();
                const url = `${Routes.STORES_SEARCH}/${encodeStoreAddress(parameter)}`;
                return (
                  <Box key={city.name} className={CITIES_LIST_CLASSES.cityBlock}>
                    <CombinedButton
                      href={url}
                      fullWidth
                      onClick={handleProgress}
                      variant="text"
                      data-parameter={parameter}
                      className={CITIES_LIST_CLASSES.cityName}
                    >
                      {city.name}
                    </CombinedButton>
                  </Box>
                );
              })}
            </Box>
          );
        })}
        <Box className={CITIES_LIST_CLASSES.alphabet}>
          {alphabet.map(({ letter, enabled }) => (
            <Typography
              key={letter}
              data-nav={letter}
              component="div"
              onClick={handleMove}
              className={clsx(CITIES_LIST_CLASSES.alphabetLetter, {
                [CITIES_LIST_CLASSES.alphabetLetterDisabled]: !enabled,
              })}
            >
              {letter}
            </Typography>
          ))}
        </Box>
      </CitiesListContainer>
    </>
  );
};
