import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { STORE_CARD_CLASSES } from "~/components/store-locator-page/components/store-card/constants";
import { COLOR_BLACK } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { STORES_LIST_CLASSES } from "./constants";

interface UseStylesProps {
  isHeaderAvailable?: boolean;
  carouselHeight?: number;
  isEmpty?: boolean;
}

export const StoresListContainer = styled(Box, {
  shouldForwardProp: (prop: string) =>
    !["isHeaderAvailable", "isEmpty", "carouselHeight"].includes(prop),
})<UseStylesProps>(({ theme, isHeaderAvailable, carouselHeight, isEmpty }) => {
  const { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } = getFormatMedia(theme);

  return {
    flexGrow: 1,
    marginTop: theme.spacing(isHeaderAvailable ? 5 : 0),
    position: "relative",
    [BREAKPOINT_DESKTOP]: {
      height: 600,
      maxHeight: 600,
      overflowY: isEmpty ? "hidden" : "scroll",
      overflowX: "hidden",
    },

    [`& .${STORES_LIST_CLASSES.header}`]: {
      display: "none",
      [BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(4),
        display: "block",
      },
    },

    [`& .${STORES_LIST_CLASSES.divider}`]: {
      margin: theme.spacing(3, 0, 2, 0),
      [BREAKPOINT_DESKTOP]: {
        marginRight: theme.spacing(2.5),
      },
    },

    [`& .${STORES_LIST_CLASSES.tabs}`]: {
      paddingLeft: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(4),
      [BREAKPOINT_TABLET]: {
        paddingLeft: theme.spacing(4),
        marginLeft: theme.spacing(-4),
        paddingRight: theme.spacing(4),
        marginRight: theme.spacing(-4),
      },
      [BREAKPOINT_DESKTOP]: {
        display: "none",
      },
    },

    [`& .${STORES_LIST_CLASSES.tab}`]: {
      minWidth: "50%",
      [BREAKPOINT_TABLET]: {
        minWidth: "auto",
      },
    },

    [`& .${STORES_LIST_CLASSES.map}`]: {
      paddingBottom: "110.7%",
      paddingLeft: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(4),
      [BREAKPOINT_TABLET]: {
        paddingBottom: "75%",
        paddingLeft: theme.spacing(4),
        marginLeft: theme.spacing(-4),
        paddingRight: theme.spacing(4),
        marginRight: theme.spacing(-4),
      },
      [BREAKPOINT_DESKTOP]: {
        display: "none",
      },
    },

    [`& .${STORES_LIST_CLASSES.carousel}`]: {
      marginTop: theme.spacing(-10),
      marginInline: theme.spacing(-2),
      [BREAKPOINT_TABLET]: {
        marginInline: theme.spacing(-4),
      },
      [BREAKPOINT_DESKTOP]: {
        display: "none",
      },
    },

    [`& .${STORES_LIST_CLASSES.slide}`]: {
      flexGrow: 1,
      border: "none",
      boxShadow:
        "0px 0px 4px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
      paddingBottom: theme.spacing(2),
      height: carouselHeight ?? "auto",
      transition: "height 100ms",
      [BREAKPOINT_TABLET]: {
        paddingBottom: theme.spacing(3),
      },
      [`.${STORE_CARD_CLASSES.buttonLabel}`]: {
        padding: theme.spacing(2),
        border: "2px solid transparent",
        marginBottom: theme.spacing(3),
        [BREAKPOINT_TABLET]: {
          padding: theme.spacing(3),
        },
      },
    },

    [`& .${STORES_LIST_CLASSES.slideSelected}`]: {
      [`.${STORE_CARD_CLASSES.buttonLabel}`]: {
        border: `2px solid ${COLOR_BLACK}`,
      },
    },
  };
});
