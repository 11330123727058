export const CITIES_LIST_PREFIX = "CitiesList";

export const CITIES_LIST_CLASSES = {
  backdrop: `${CITIES_LIST_PREFIX}-backdrop`,
  title: `${CITIES_LIST_PREFIX}-title`,
  letter: `${CITIES_LIST_PREFIX}-letter`,
  alphabet: `${CITIES_LIST_PREFIX}-alphabet`,
  alphabetLetter: `${CITIES_LIST_PREFIX}-alphabetLetter`,
  alphabetLetterDisabled: `${CITIES_LIST_PREFIX}-alphabetLetterDisabled`,
  cityBlock: `${CITIES_LIST_PREFIX}-cityBlock`,
  cityName: `${CITIES_LIST_PREFIX}-cityName`,
};
