import React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { CITIES_LIST_CLASSES } from "~/components/store-locator-page/components/cities-list/constants";
import {
  COLOR_BUTTON_HOVER,
  COLOR_GREYSCALE_BLACK_54,
  COLOR_GREYSCALE_BLACK_8,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

export interface UseStylesProps {
  listTop?: number;
}

export const BackdropStyled = styled(Backdrop)(() => ({
  zIndex: 1000,
}));

const CitiesListContainerComponent = React.forwardRef<
  HTMLDivElement,
  UseStylesProps & BoxProps
>(({ listTop, ...restProps }, ref) => <Box ref={ref} {...restProps} />);

CitiesListContainerComponent.displayName = "CitiesListContainer";

export const CitiesListContainer = styled(CitiesListContainerComponent)(({
  theme,
  listTop,
}) => {
  const { BREAKPOINT_DESKTOP } = getFormatMedia(theme);

  return {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    overflowY: "hidden",
    overflowX: "hidden",
    position: "relative",
    [BREAKPOINT_DESKTOP]: {
      height: 600,
      maxHeight: 600,
      overflowY: "scroll",
    },

    [`& .${CITIES_LIST_CLASSES.title}`]: {
      marginBottom: theme.spacing(3.5),
    },

    [`& .${CITIES_LIST_CLASSES.letter}`]: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2),
      color: COLOR_GREYSCALE_BLACK_54,
      "&:after": {
        content: "''",
        borderTopColor: COLOR_GREYSCALE_BLACK_8,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(3),
        flexGrow: 1,
        [BREAKPOINT_DESKTOP]: {
          marginRight: theme.spacing(2),
        },
      },
    },

    [`& .${CITIES_LIST_CLASSES.alphabet}`]: {
      transition: "top 50ms",
      position: "absolute",
      right: 0,
      top: Math.max(0, (listTop ?? 0) < -56 ? -(listTop ?? 0) + 16 : 56),
      [BREAKPOINT_DESKTOP]: {
        display: "none",
      },
    },

    [`& .${CITIES_LIST_CLASSES.alphabetLetter}`]: {
      fontSize: 12,
      cursor: "pointer",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "24px",
      textAlign: "center",
    },

    [`& .${CITIES_LIST_CLASSES.alphabetLetterDisabled}`]: {
      color: COLOR_GREYSCALE_BLACK_54,
      cursor: "default",
    },

    [`& .${CITIES_LIST_CLASSES.cityBlock}`]: {
      paddingLeft: theme.spacing(0.25),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [BREAKPOINT_DESKTOP]: {
        paddingRight: theme.spacing(0.25),
      },
    },

    [`& .${CITIES_LIST_CLASSES.cityName}`]: {
      justifyContent: "left",
      padding: 0,
      height: "auto",
      "&:hover": {
        backgroundColor: "transparent",
        [BREAKPOINT_DESKTOP]: {
          backgroundColor: COLOR_BUTTON_HOVER,
        },
      },
    },
  };
});

CitiesListContainer.displayName = "CitiesListContainer";
