"use client";
import React, { useState, useEffect, useRef } from "react";

import { Store } from "~/bff/types/Store";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Nullable, Optional } from "~/types/general.types";

export interface MapProps extends google.maps.MapOptions {
  className?: string;
  stores?: Nullable<Store>[] | undefined;
  selected?: Store;
  onStoreSelect?: (store?: Store) => void;
  onZoom?: (zoom?: number | undefined) => void;
}

export const Map: React.FC<MapProps> = ({
  className,
  stores,
  selected,
  onStoreSelect,
  onZoom,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Optional<google.maps.Map>>();
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

  const markersRef = useRef<google.maps.Marker[]>(markers);
  markersRef.current = markers;

  const selectedRef = useRef<Optional<Store>>(selected);
  selectedRef.current = selected;

  const onStoreClickRef = useRef<Optional<(store?: Store) => void>>(onStoreSelect);
  onStoreClickRef.current = onStoreSelect;

  const onZoomRef = useRef<Optional<(zoom?: number) => void>>(onZoom);
  onZoomRef.current = onZoom;

  const optionsRef = useRef<Optional<google.maps.MapOptions>>(options);
  optionsRef.current = options;

  useEffect(() => {
    if (ref.current && !map && typeof google !== "undefined") {
      const init = new google.maps.Map(ref.current, {});
      setMap(init);
      google.maps.event.addListener(init, "bounds_changed", () => {
        if (onZoomRef.current) {
          onZoomRef.current(init.getZoom());
        }
      });
    }
  }, [ref, map]);

  useEffect(() => {
    if (map && !selected) {
      map.setOptions(options);
    }
  }, [map, options, selected]);

  useEffect(() => {
    if (markersRef.current.length !== 0) {
      for (const marker of markersRef.current) {
        marker.setMap(null);
      }
    }
    if (!stores || stores.length === 0) {
      setMarkers([]);
      return;
    }
    const newMarkers: google.maps.Marker[] = [];
    for (const store of stores) {
      if (!store?.displayCoordinate) {
        continue;
      }
      const {
        displayCoordinate: { latitude: lat, longitude: lng },
      } = store;
      const marker = new google.maps.Marker({
        map: map,
        position: { lat, lng },
        icon:
          store === selectedRef.current
            ? "/assets/icons/selected-store-marker.svg"
            : "/assets/icons/store-marker.svg",
        clickable: true,
        zIndex: store === selectedRef.current ? 2 : 1,
        title: store?.geomodifier ?? "",
      });
      marker.addListener("click", () => {
        for (const marker of newMarkers) {
          marker.setIcon("/assets/icons/store-marker.svg");
          marker.setZIndex(1);
        }
        marker.setIcon("/assets/icons/selected-store-marker.svg");
        const position = marker.getPosition();
        if (position) {
          map?.panTo(position);
        }
        marker.setZIndex(2);
        if (onStoreClickRef.current && selectedRef.current !== store) {
          onStoreClickRef.current(store);
        }
      });
      newMarkers.push(marker);
    }
    setMarkers(newMarkers);
  }, [map, stores]);

  useEffect(() => {
    const {
      displayCoordinate: { longitude, latitude } = {
        longitude: undefined,
        latitude: undefined,
      },
    } = selected ?? {};
    for (const marker of markers) {
      if (!longitude || !latitude) {
        marker.setIcon("/assets/icons/store-marker.svg");
        marker.setZIndex(1);
        continue;
      }
      const position = new google.maps.LatLng(latitude, longitude);
      if (marker.getPosition()?.equals(position)) {
        marker.setIcon("/assets/icons/selected-store-marker.svg");
        marker.setZIndex(2);
        map?.panTo(position);
      } else {
        marker.setIcon("/assets/icons/store-marker.svg");
        marker.setZIndex(1);
      }
    }
  }, [map, markers, selected]);

  return <div className={className} ref={ref} {...getTestAutomationProps("map")} />;
};
