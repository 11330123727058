import React from "react";

import Container, { ContainerOwnProps } from "@mui/material/Container";
import { styled } from "@mui/material/styles";

import { COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { STORE_LOCATOR_PAGE_CLASSES } from "./constants";

export interface UseStylesProps {
  isSearchControlAvailable: boolean;
}

export const StoreLocatorPageContainer = styled(
  ({
    isSearchControlAvailable,
    ...restProps
  }: UseStylesProps & ContainerOwnProps) => <Container {...restProps} />,
)(({ theme, isSearchControlAvailable }) => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);

  return {
    paddingBottom: theme.spacing(6),
    backgroundColor: COLOR_WHITE,

    [`& .${STORE_LOCATOR_PAGE_CLASSES.header}`]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(2),
      [BREAKPOINT_TABLET]: {
        paddingTop: theme.spacing(!isSearchControlAvailable ? 0 : 2),
      },
      [BREAKPOINT_DESKTOP]: {
        paddingTop: theme.spacing(!isSearchControlAvailable ? 0 : 3),
      },
    },

    [`& .${STORE_LOCATOR_PAGE_CLASSES.map}`]: {
      display: "none",
      overflow: "hidden",
      "& > div": {
        height: "100%",
      },
      [BREAKPOINT_DESKTOP]: {
        display: "block",
      },
    },

    [`& .${STORE_LOCATOR_PAGE_CLASSES.breadcrumbs}`]: {
      [BREAKPOINT_TABLET]: {
        paddingTop: theme.spacing(1),
      },
    },
  };
});
