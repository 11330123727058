import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { TAG_CLASSES } from "~/components/tag/constants";
import { ChipType } from "~/theme/chip";
import {
  COLOR_ADDITIONAL_WARM_GRAY_3U,
  COLOR_BLACK,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_NEON_GREEN,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { STORE_CARD_CLASSES } from "./constants";

export const StoreCardContainer = styled(Box)(({ theme }) => {
  const { BREAKPOINT_TABLET } = getFormatMedia(theme);

  return {
    backgroundColor: COLOR_ADDITIONAL_WARM_GRAY_3U,
    height: "100%",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    [BREAKPOINT_TABLET]: {
      padding: theme.spacing(3),
    },

    [`& .${STORE_CARD_CLASSES.infoBox}`]: {
      marginBottom: theme.spacing(3),
    },

    [`& .${STORE_CARD_CLASSES.titleBox}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
    },

    [`& .${STORE_CARD_CLASSES.storeInfo}`]: {
      ...theme.typography.body2,
      [BREAKPOINT_TABLET]: {
        ...theme.typography.body1,
      },
    },

    [`& .${STORE_CARD_CLASSES.clickAndCollectTrialStoreTag}`]: {
      marginTop: theme.spacing(1),

      [`&.${TAG_CLASSES.chipTag}`]: {
        [`&.${ChipType.tag}`]: {
          backgroundColor: COLOR_NEON_GREEN,
          color: COLOR_BLACK,
          "&:hover": {
            color: COLOR_GREYSCALE_BLACK_75,
          },
        },
      },
    },

    [`& .${STORE_CARD_CLASSES.services}`]: {
      display: "flex",
    },
  };
});
