import {
  BffComponentType as Component,
  ComponentsConfig,
} from "~/bff/ComponentsConfig";
import { StoreLocatorPage } from "~/components/store-locator-page/component";

export const storeLocatorPageConfig: ComponentsConfig = {
  [Component.STORE_LOCATOR_PAGE]: {
    component: StoreLocatorPage,
  },
};
