import React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { STORE_CARD_CLASSES as BASE_STORE_CARD_CLASSES } from "~/components/nearby-stores/components/store-card/constants";
import { STORE_CARD_CLASSES } from "~/components/store-locator-page/components/store-card/constants";
import { COLOR_BLACK, COLOR_PRIMARY_BLACK } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

interface UseStylesProps {
  asSlide?: boolean;
  selected?: boolean;
}

export const BackdropStyled = styled(Backdrop)(() => ({
  zIndex: 1000,
}));

export const StoreCardContainer = styled(
  ({ asSlide, selected, ...rest }: UseStylesProps & BoxProps) => <Box {...rest} />,
)<UseStylesProps>(({ theme, asSlide, selected }) => {
  const { BREAKPOINT_DESKTOP, BREAKPOINT_TABLET } = getFormatMedia(theme);

  return {
    paddingRight: 0,
    [BREAKPOINT_DESKTOP]: {
      paddingRight: 25,
    },

    [`& .${STORE_CARD_CLASSES.button}`]: {
      height: "auto",
      padding: 0,
      [BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(2),
      },
      "&:after": {
        borderWidth: 0,
        pointerEvents: "none",
        [BREAKPOINT_DESKTOP]: {
          borderWidth: selected ? 2 : 0,
        },
      },
      "&:active, &:active, &:hover": {
        backgroundColor: "transparent !important",
        "&:after": {
          [BREAKPOINT_DESKTOP]: {
            borderWidth: 2,
          },
        },
      },
      "&:before": {
        content: "none",
        display: "none !important",
      },
      "&:focus-visible:after": {
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: COLOR_PRIMARY_BLACK,
      },
    },

    [`& .${STORE_CARD_CLASSES.buttonLabel}`]: {
      display: "block",
      textTransform: "none",
      height: "100%",
      position: "relative",
      textAlign: "left",
      marginBottom: theme.spacing(1.5),
      [BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(2),
      },
    },

    [`& .${STORE_CARD_CLASSES.storeCard}`]: {
      backgroundColor: "transparent !important",
      padding: 0,
      maxWidth: "100%",
      "& .StoreCard-infoBox": {
        marginBottom: 0,
      },
      [`& .${BASE_STORE_CARD_CLASSES.services}`]: {
        marginTop: theme.spacing(3),
      },
    },

    [`& .${STORE_CARD_CLASSES.storeDetails}`]: {
      color: COLOR_BLACK,
      ...theme.typography.body2,
      [BREAKPOINT_TABLET]: {
        display: asSlide || asSlide === undefined ? "inline" : "none",
        ...theme.typography.body1,
        marginLeft: asSlide ? theme.spacing(3) : 0,
      },
      padding: 0,
      marginLeft: theme.spacing(2),
      display: asSlide ? "inline" : "none",
      backgroundColor: "transparent !important",
      "&, &>*": {
        textDecoration: "underline !important",
      },
      [BREAKPOINT_DESKTOP]: {
        display: asSlide === undefined ? "inline" : "none",
        marginLeft: theme.spacing(2),
      },
    },
  };
});
