import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { SEARCH_FIELD_CLASSES } from "./constants";

export const BackdropStyled = styled(Backdrop)(() => ({
  zIndex: 1000,
}));

export const SearchFieldContainer = styled(Box)(() => {
  return {
    display: "flex",
    alignItems: "center",

    [`& .${SEARCH_FIELD_CLASSES.label}`]: {
      marginRight: "auto",
    },
  };
});
