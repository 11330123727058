import React, { useCallback, useState } from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { kebabCase } from "lodash";
import dynamic from "next/dynamic";
import { useParams, useSearchParams, useRouter } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Coordinate } from "~/bff/types/Coordinate";
import { Store } from "~/bff/types/Store";
import { StoreLocatorPageTranslations } from "~/bff/types/StoreLocatorPageTranslations";
import { CustomLocationSearch } from "~/components/custom-location-search/component";
import { getZoom } from "~/components/map/helpers";
import {
  DEFAULT_ZOOM,
  MY_LOCATION_ZOOM,
} from "~/components/store-locator-page/constants";
import { PlaceApiProvider } from "~/constants/data-layer";
import { Params, Variables } from "~/constants/request";
import { STORE_LOCATOR_SEARCH_TEXT } from "~/constants/session-storage";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { Country } from "~/helpers/country/types";
import { getStoresByPlaceId } from "~/helpers/get-stores-by-place-id";
import { pushHistoryState } from "~/helpers/history";
import { importComponentByName } from "~/helpers/import-component-by-name";
import { setItem } from "~/hooks/use-session-storage/hook";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Nullable } from "~/types/general.types";
import { Logger } from "~/utils/logger";

import { SEARCH_FIELD_CLASSES } from "./constants";
import { findByAddress, findByGeolocation, Result } from "./helpers";
import { BackdropStyled, SearchFieldContainer } from "./styled";

const ErrorDialog = dynamic(async () =>
  importComponentByName(import("../error-dialog/component"), "ErrorDialog"),
);
const LocationSearch = dynamic(async () =>
  importComponentByName(import("../location-search/component"), "LocationSearch"),
);
export interface SearchFieldProps {
  country?: Country;
  onSearch?: (result: {
    query?: string;
    place?: Coordinate;
    stores: Nullable<Store[]>;
  }) => void;
  translations?: StoreLocatorPageTranslations;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  country,
  onSearch,
  translations,
}) => {
  const { searchDescription, searchPlaceholder, locationButtonName } =
    translations ?? {};
  const theme = useTheme();
  const router = useRouter();
  const { locale } = useParams<GlobalPathParams>();
  const searchParams = useSearchParams();
  const pageLocale = locale;
  const config = useAzureConfigurator(locale);

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const countryName = useVariable<string>(Variables.COUNTRY_NAME);

  const isCustomSearchEnabled = config?.featureFlags?.customLocationSearch?.enabled;

  const handleErrorClose = useCallback(() => setErrorMessage(null), []);
  const handleUseMyLocation = useCallback(async () => {
    try {
      setInProgress(true);
      const result = await findByGeolocation(pageLocale);
      if (!result || !result.place) {
        return;
      }
      const {
        stores,
        place: { latitude, longitude },
      } = result;

      const query = new URLSearchParams(searchParams);
      query.set(Params.COUNTRY, kebabCase(countryName));
      query.set(Params.LATITUDE, latitude.toString());
      query.set(Params.LONGITUDE, longitude.toString());
      query.set(
        Params.ZOOM,
        (stores && stores.length !== 0
          ? getZoom(theme, stores)
          : MY_LOCATION_ZOOM
        ).toString(),
      );
      // eslint-disable-next-line no-restricted-globals
      pushHistoryState(`?${query.toString()}`);
      router.refresh();

      onSearch?.(result);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error);
      Logger.getLogger().error(errorMessage);
      setErrorMessage(String(error));
      throw error;
    } finally {
      setInProgress(false);
    }
  }, [pageLocale, searchParams, countryName, onSearch, theme, router]);

  const handleSearch = useCallback(
    async (query: string, placeId?: string, isCustomSearch = false) => {
      if (!query.trim()) {
        const queryParams = new URLSearchParams(searchParams);
        queryParams.set(Params.COUNTRY, kebabCase(countryName));
        queryParams.delete(Params.LATITUDE);
        queryParams.delete(Params.LONGITUDE);
        queryParams.delete(Params.STORE_QUERY);
        queryParams.delete(Params.ZOOM);
        // eslint-disable-next-line no-restricted-globals
        pushHistoryState(`?${queryParams.toString()}`);
        router.refresh();
        onSearch?.({
          stores: null,
        });
        return;
      }
      try {
        setItem(STORE_LOCATOR_SEARCH_TEXT, query);
        setInProgress(true);
        let result: Nullable<Result>;
        if (!country) {
          return;
        }
        if (isCustomSearch && placeId) {
          result = await getStoresByPlaceId(
            pageLocale,
            country?.code2,
            placeId,
            PlaceApiProvider.google,
          );
        } else {
          result = await findByAddress(pageLocale, country?.code2, query);
        }
        if (!result) {
          return;
        }
        const {
          stores,
          place: { latitude, longitude } = {
            latitude: undefined,
            longitude: undefined,
          },
        } = result;
        if (result.place && latitude && longitude) {
          const queryParams = new URLSearchParams(searchParams);
          queryParams.set(Params.COUNTRY, kebabCase(countryName));
          queryParams.set(Params.LATITUDE, latitude.toString());
          queryParams.set(Params.LONGITUDE, longitude.toString());
          queryParams.set(Params.STORE_QUERY, query.toString());
          queryParams.set(
            Params.ZOOM,
            stores && stores.length !== 0
              ? getZoom(theme, stores).toString()
              : DEFAULT_ZOOM.toString(),
          );
          // eslint-disable-next-line no-restricted-globals
          pushHistoryState(`?${queryParams.toString()}`);
        } else {
          const queryParams = new URLSearchParams(
            Array.from(searchParams.entries()),
          );
          queryParams.set(Params.COUNTRY, kebabCase(countryName));
          queryParams.set(Params.STORE_QUERY, query.toString());
          queryParams.set(Params.ZOOM, (country.zoom ?? DEFAULT_ZOOM).toString());
          // eslint-disable-next-line no-restricted-globals
          pushHistoryState(`?${query.toString()}`);
        }
        onSearch?.({ ...result, query });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);
        Logger.getLogger().error(errorMessage);
        setErrorMessage(String(error));
        throw error;
      } finally {
        setInProgress(false);
        router.refresh();
      }
    },
    [searchParams, countryName, onSearch, country, pageLocale, theme, router],
  );

  const displayLoader = () => {
    setInProgress(true);
  };

  return (
    <>
      <BackdropStyled open={inProgress}>
        <CircularProgress color="inherit" />
      </BackdropStyled>
      <SearchFieldContainer>
        <Typography
          id="store-search-description"
          className={SEARCH_FIELD_CLASSES.label}
          variant="body2"
        >
          {searchDescription}
        </Typography>
        <Button
          className="body2 underlined"
          variant="text"
          onClick={handleUseMyLocation}
        >
          {locationButtonName}
        </Button>
      </SearchFieldContainer>
      {isCustomSearchEnabled ? (
        <CustomLocationSearch
          searchPlaceholder={searchPlaceholder}
          handleSearch={handleSearch}
          isStoreLocatorPage={true}
          displayLoader={displayLoader}
        />
      ) : (
        <LocationSearch
          searchPlaceholder={searchPlaceholder}
          handleSearch={handleSearch}
        />
      )}
      <ErrorDialog message={errorMessage} onClose={handleErrorClose} />
    </>
  );
};
