import { StoreDistance } from "~/bff/types/StoreDistance";
import { LOCALES } from "~/constants/i18n";
import { Nullable } from "~/types/general.types";

import { Dictionary, DictionaryKeys } from "../../context/dictionary-label/types";

const localesWithMiles = ["en-gb", "en-ie", "en-us"];

const sliceDistance = (distance: Nullable<number> | undefined): string => {
  if (!distance && distance !== 0) {
    return "";
  }
  return `${Math.floor(distance * 100) / 100}`;
};

export const getStoreDistance = (
  locale: LOCALES,
  distance: StoreDistance,
  dictionary?: Dictionary,
): string => {
  const { distanceKilometers, distanceMiles } = distance || {};
  const milesKey = dictionary?.[DictionaryKeys.Miles] ?? "";
  const kilometersKey = dictionary?.[DictionaryKeys.Kilometers] ?? "";

  const slicedDistanceMiles = sliceDistance(distanceMiles);
  const slicedDistanceKilometers = sliceDistance(distanceKilometers);

  if (localesWithMiles?.includes(locale)) {
    return slicedDistanceMiles ? `${slicedDistanceMiles} ${milesKey}` : "";
  } else {
    return slicedDistanceKilometers
      ? `${slicedDistanceKilometers} ${kilometersKey}`
      : "";
  }
};
