import { Theme } from "@mui/material/styles";

import { Store } from "~/bff/types/Store";
import { DEFAULT_ZOOM } from "~/components/store-locator-page/constants";
import { Nullable } from "~/types/general.types";

const getMapWidth = (muiTheme: Theme) => {
  const width = IS_SERVER ? 600 : innerWidth;
  if (width > muiTheme.breakpoints.values.md) {
    const separator = 24;
    const padding = width > muiTheme.breakpoints.values.lg ? 64 : 32;
    const element = (width - padding * 2 - separator * 11) / 12;
    return Math.round(element * 8 + separator * 7);
  }
  return width;
};

export const getZoom = (muiTheme: Theme, stores?: Nullable<Store>[]): number => {
  const width = getMapWidth(muiTheme);

  let west = 0;
  let east = 360;

  if (!stores || stores.length === 0) {
    return DEFAULT_ZOOM;
  }

  for (const store of stores) {
    if (!store || !store.displayCoordinate) {
      continue;
    }
    west = Math.min(west, store.displayCoordinate.longitude);
    east = Math.max(east, store.displayCoordinate.longitude);
  }

  let angle = east - west;
  if (angle < 0) {
    angle += 360;
  }
  return Math.round(Math.log((width * 360) / angle) / Math.LN2);
};

export const getCenter = (
  stores?: Nullable<Store>[] | undefined,
): google.maps.LatLngLiteral => {
  if (!stores || stores.length === 0) {
    return {
      lat: 0,
      lng: 0,
    };
  }
  return stores?.reduce(
    (accumulator, store) => {
      const { lat: latitude, lng: longitude } = accumulator;

      if (!store || !store.displayCoordinate) {
        return accumulator;
      }

      const { displayCoordinate } = store;
      if (latitude === 0 && longitude === 0) {
        const { latitude: lat, longitude: lng } = displayCoordinate;
        return { lat, lng };
      }
      return {
        lat: (latitude + displayCoordinate.latitude) / 2,
        lng: (longitude + displayCoordinate.longitude) / 2,
      };
    },
    {
      lat: 0,
      lng: 0,
    } as google.maps.LatLngLiteral,
  );
};
