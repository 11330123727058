import React from "react";

import Typography from "@mui/material/Typography";

import { NO_RESULTS_CLASSES } from "~/components/store-selector/components/no-results/constants";

import { NoResultsContainer } from "./styled";

interface NoResultsProps {
  noSearchResultsTitle?: string;
  noSearchResultsSubtitle?: string;
}

export const NoResults: React.FC<NoResultsProps> = ({
  noSearchResultsTitle,
  noSearchResultsSubtitle,
}) => {
  return (
    <NoResultsContainer>
      <Typography component="h5" className={NO_RESULTS_CLASSES.header}>
        {noSearchResultsTitle}
      </Typography>
      <Typography variant="body2" className={NO_RESULTS_CLASSES.description}>
        {noSearchResultsSubtitle}
      </Typography>
    </NoResultsContainer>
  );
};
