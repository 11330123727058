import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { NO_RESULTS_CLASSES } from "~/components/store-selector/components/no-results/constants";

export const NoResultsContainer = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(14.5, 5),

    [`& .${NO_RESULTS_CLASSES.header}`]: {
      fontWeight: 700,
      textAlign: "center",
    },

    [`& .${NO_RESULTS_CLASSES.description}`]: {
      textAlign: "center",
    },
  };
});
