import React, { useContext, useMemo } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { lowerCase } from "lodash";
import moment from "moment-timezone";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Store } from "~/bff/types/Store";
import { STORE_CARD_CLASSES } from "~/components/nearby-stores/components/store-card/constants";
import { isDay } from "~/components/nearby-stores/components/store-card/helpers";
import { StoreCardContainer } from "~/components/nearby-stores/components/store-card/styled";
import { isOpen } from "~/components/store-details-page/utils/is-open";
import { Tag } from "~/components/tag/component";
import { TagType } from "~/components/tag/types";
import { DictionaryLabelContext } from "~/context/dictionary-label/context";
import { DictionaryKeys } from "~/context/dictionary-label/types";
import { getStoreDistance } from "~/helpers/get-store-distance";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useClickAndCollectTrialStore } from "~/hooks/use-click-and-collect-trial-store";
import { SmallBullet24 } from "~/theme/icons/components/small-bullet";
import { formatStoreAddress, formatStoreName } from "~/utils/format-store-details";

export interface StoreCardProps {
  store: Store | undefined;
  className?: string;
  storeOpenText?: string;
  storeClosedText?: string;
}

export const StoreCard: React.FC<StoreCardProps> = ({
  store,
  className,
  storeOpenText,
  storeClosedText,
}) => {
  const dictionary = useContext(DictionaryLabelContext);
  const { locale } = useParams<GlobalPathParams>();
  const { shouldDisplayCCTag } = useClickAndCollectTrialStore(store);

  const now = moment();
  now.tz(store?.timezone ?? "");
  const day = lowerCase(moment().locale("en").format("dddd"));

  const openNowLabel = useMemo(() => {
    if (!store || !store.hours || !isDay(day) || !store.hours[day]) {
      return null;
    }
    return isOpen(now, store.hours[day] ?? {})
      ? `${storeOpenText} ${store.hours[day]?.openIntervals?.[0]?.end}`
      : `${storeClosedText} ${store.hours[day]?.openIntervals?.[0]?.start}`;
  }, [store, day, now, storeOpenText, storeClosedText]);

  return (
    <StoreCardContainer
      className={className}
      {...getTestAutomationProps("store-card")}
    >
      <Box className={STORE_CARD_CLASSES.infoBox}>
        <Box className={STORE_CARD_CLASSES.titleBox}>
          <Typography variant="h5" {...getTestAutomationProps("name")}>
            {formatStoreName(store, locale)}
          </Typography>
          <Typography variant="body2" {...getTestAutomationProps("distance")}>
            {getStoreDistance(locale, store?.distance ?? {}, dictionary ?? {})}
          </Typography>
        </Box>
        <Typography
          className={STORE_CARD_CLASSES.storeInfo}
          {...getTestAutomationProps("address")}
        >
          {formatStoreAddress(store, locale)}
        </Typography>
        {openNowLabel ? (
          <Typography
            className={STORE_CARD_CLASSES.storeInfo}
            {...getTestAutomationProps("open-time")}
          >
            {openNowLabel}
          </Typography>
        ) : undefined}
        {shouldDisplayCCTag && (
          <Tag
            className={STORE_CARD_CLASSES.clickAndCollectTrialStoreTag}
            type={TagType.clickAndCollectTrialStore}
            title={dictionary?.[DictionaryKeys.ClickPlusCollectTrialStore]}
          />
        )}
      </Box>
      {store?.services && (
        <Box className={STORE_CARD_CLASSES.services}>
          <SmallBullet24 />
          <Typography
            className={STORE_CARD_CLASSES.storeInfo}
            {...getTestAutomationProps("store-services")}
          >
            <Box component="strong">
              {store.services.map((service) => service.name).join(", ")}
            </Box>
          </Typography>
        </Box>
      )}
    </StoreCardContainer>
  );
};
