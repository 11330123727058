export enum StoresView {
  LIST,
  MAP,
}

export const STORES_LIST_PREFIX = "StoresList";

export const STORES_LIST_CLASSES = {
  header: `${STORES_LIST_PREFIX}-header`,
  divider: `${STORES_LIST_PREFIX}-divider`,
  tabs: `${STORES_LIST_PREFIX}-tabs`,
  tab: `${STORES_LIST_PREFIX}-tab`,
  map: `${STORES_LIST_PREFIX}-map`,
  carousel: `${STORES_LIST_PREFIX}-carousel`,
  slide: `${STORES_LIST_PREFIX}-slide`,
  slideSelected: `${STORES_LIST_PREFIX}-slideSelected`,
};
