import React, { useCallback, useMemo, MouseEvent, useState } from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams, useRouter } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Store } from "~/bff/types/Store";
import { StoreInPageTranslations } from "~/bff/types/StoreInPageTranslations";
import { StoreLocatorPageTranslations } from "~/bff/types/StoreLocatorPageTranslations";
import { CombinedButton } from "~/components/combined-button/component";
import { StoreCard as StoreCardBase } from "~/components/nearby-stores/components/store-card/component";
import { Routes } from "~/constants/request";
import { Country } from "~/helpers/country/types";
import { encodeStoreAddress } from "~/helpers/encode-store-address";
import { getLinkUrl } from "~/helpers/locales";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getFormatMedia } from "~/theme/utils";
import { Nullable } from "~/types/general.types";

import { STORE_CARD_CLASSES } from "./constants";
import { BackdropStyled, StoreCardContainer } from "./styled";

export interface StoreCardProps {
  store?: Nullable<Store>;
  country?: Country;
  asSlide?: boolean;
  selected?: boolean;
  onStoreSelect?: (store?: Store) => void; // triggers when user clicks on the store card and then user IS NOT redirected to the store details page
  onRedirectToStoreDetailsPage?: (store?: Store) => void; // triggers when user clicks on the store card and then user IS redirected to the store details page
  translations?: StoreInPageTranslations | StoreLocatorPageTranslations;
}

export const StoreCard: React.FC<StoreCardProps> = ({
  store,
  selected,
  asSlide,
  onStoreSelect,
  onRedirectToStoreDetailsPage,
  translations,
}) => {
  const line1 = store?.address.line1;
  const city = store?.address.city;
  const theme = useTheme();
  const router = useRouter();
  const { locale } = useParams<GlobalPathParams>();
  const { BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  const isDesktop = useMediaQuery(BREAKPOINT_DESKTOP);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const url = useMemo(
    () =>
      getLinkUrl(locale, Routes.STORES_STORE)
        .replace("[city]", encodeStoreAddress(city ?? ""))
        .replace("[address]", encodeStoreAddress(line1 ?? "")),
    [city, line1, locale],
  );

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      let element: HTMLElement | null = event.target as HTMLElement;
      while (element && element.tagName !== "A") {
        element = element.parentElement;
      }

      if (element && store) {
        setInProgress(true);
        onRedirectToStoreDetailsPage?.(store);
      } else if (!isDesktop && !asSlide && store) {
        setInProgress(true);
        onRedirectToStoreDetailsPage?.(store);
        router.push(url);
      } else if (onStoreSelect && store) {
        onStoreSelect(store);
      }
    },
    [
      isDesktop,
      asSlide,
      onStoreSelect,
      onRedirectToStoreDetailsPage,
      router,
      url,
      store,
    ],
  );

  return (
    <>
      <BackdropStyled open={inProgress}>
        <CircularProgress color="inherit" />
      </BackdropStyled>
      <StoreCardContainer
        asSlide={asSlide}
        selected={selected}
        {...getTestAutomationProps("store-card")}
      >
        <Button
          fullWidth
          component="div"
          variant="outlined"
          onClick={handleClick}
          classes={{
            root: `${STORE_CARD_CLASSES.button} ${STORE_CARD_CLASSES.buttonLabel}`,
          }}
        >
          <StoreCardBase
            store={store ?? undefined}
            className={STORE_CARD_CLASSES.storeCard}
            storeOpenText={translations?.storeOpenedText ?? ""}
            storeClosedText={translations?.storeClosedText ?? ""}
          />
        </Button>
        <CombinedButton
          href={url}
          variant="text"
          className={STORE_CARD_CLASSES.storeDetails}
          aria-label={`${translations?.storeDetailsLinkName} ${store?.geomodifier || ""}`}
          {...getTestAutomationProps("details")}
        >
          {translations?.storeDetailsLinkName}
        </CombinedButton>
      </StoreCardContainer>
    </>
  );
};
