"use client";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";

import { useIsAuthenticated } from "@azure/msal-react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import startCase from "lodash/startCase";
import { useParams, useSearchParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { StoreLocatorPageProps } from "~/bff/components/StoreLocatorPage";
import { BreadcrumbsItem } from "~/bff/types/BreadcrumbsItem";
import { Coordinate } from "~/bff/types/Coordinate";
import { Store } from "~/bff/types/Store";
import { CombinedLink } from "~/components/combined-link/component";
import { Map } from "~/components/map/component";
import { getCenter, getZoom } from "~/components/map/helpers";
import { MapWrapper } from "~/components/map-wrapper/component";
import { BloomreachPageType } from "~/constants/bloomreach";
import { DataLayerData, EventsNames, EventTypes } from "~/constants/data-layer";
import { Params, Routes, Variables } from "~/constants/request";
import { AuthContext } from "~/context/auth/context";
import { DictionaryLabelContext } from "~/context/dictionary-label/context";
import { DictionaryKeys } from "~/context/dictionary-label/types";
import { RouterHistoryContext } from "~/context/router-history/context";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { getCountry } from "~/helpers/country/get-country";
import { getCountryCode } from "~/helpers/country/get-country-code";
import { replaceHistoryState } from "~/helpers/history";
import { initBloomreachPixel } from "~/helpers/init-bloomreach-pixel";
import { pushDataLayer } from "~/helpers/push-data-layer";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Nullable, Optional } from "~/types/general.types";
import { getJsonLdForBreadcrumbs } from "~/utils/jsonLd/breadcrumbs";

import { JsonLd } from "../json-ld/component";
import { SeoMetaTags } from "../seo-meta-tags/component";

import { CitiesList } from "./components/cities-list/component";
import { SearchField } from "./components/search-field/component";
import { StoresList } from "./components/stores-list/component";
import {
  DEFAULT_ZOOM,
  MY_LOCATION_ZOOM,
  STORE_LOCATOR_PAGE_CLASSES,
} from "./constants";
import { StoreLocatorPageContainer } from "./styled";

export const StoreLocatorPage: React.FC<StoreLocatorPageProps> = ({
  cities,
  geosearch,
  translations,
  seo,
  testAutomationId = "store-locator-page",
}) => {
  const theme = useTheme();
  const { locale } = useParams<GlobalPathParams>();
  const countryCode = getCountryCode(locale);
  const searchParams = useSearchParams();
  const latParam = searchParams.get(Params.LATITUDE);
  const lngParam = searchParams.get(Params.LONGITUDE);
  const zoomParam = searchParams.get(Params.ZOOM);
  const queryParam = searchParams.get(Params.STORE_QUERY);
  const cityParam = searchParams.get(Params.CITY);

  const { stores } = geosearch ?? {};
  const country = getCountry(locale);

  const urlPrefix = useVariable<string>(Variables.URL_PREFIX);
  const countryName = useVariable<string>(Variables.COUNTRY_NAME);
  const dictionary = useContext(DictionaryLabelContext);

  const { previousPath } = useContext(RouterHistoryContext) ?? {};
  const { account } = useContext(AuthContext) ?? {};
  const isUserAuthenticated = useIsAuthenticated();

  const lastSearchQueryRef = useRef<string>();
  const [foundStores, setFoundStores] = useState<Nullable<Store[]>>();
  const [selected, setSelected] = useState<Optional<Store>>();
  const coordinates = useMemo(() => {
    const { coordinates } = country ?? {};
    if (!coordinates) {
      return { lat: 0, lng: 0 };
    }
    const { latitude: lat, longitude: lng } = coordinates;
    if (latParam && lngParam) {
      return { lat: Number(latParam), lng: Number(lngParam) };
    } else if (cityParam) {
      return getCenter(stores);
    } else {
      return { lat, lng };
    }
  }, [cityParam, latParam, lngParam, country, stores]);
  const zoom = useMemo(() => {
    if (zoomParam && /^\d+$/.test(zoomParam as string)) {
      return Number(zoomParam);
    }
    if (stores && stores.length !== 0) {
      return getZoom(theme, stores);
    }
    if (latParam && lngParam && !queryParam) {
      return MY_LOCATION_ZOOM;
    }
    return country?.zoom ?? DEFAULT_ZOOM;
  }, [zoomParam, latParam, lngParam, queryParam, country, stores, theme]);
  const preparedStores = useMemo(() => foundStores ?? stores, [foundStores, stores]);
  const isSearchControlAvailable = useMemo(() => !cityParam, [cityParam]);
  const isStoresViewAvailable = useMemo(
    () => Boolean(cityParam ?? latParam ?? lngParam ?? queryParam),
    [cityParam, latParam, lngParam, queryParam],
  );
  const cityName = useMemo(() => stores?.[0]?.address?.city ?? "", [stores]);

  const handleZoom = useCallback(
    (zoom: number | undefined) => {
      if (zoom && zoomParam !== `${zoom}`) {
        const query = new URLSearchParams(searchParams);
        query.set(Params.ZOOM, `${zoom}`);
        // eslint-disable-next-line no-restricted-globals
        replaceHistoryState(`?${query.toString()}`);
      }
    },
    [searchParams, zoomParam],
  );

  const handleSearch = useCallback(
    (result: { query?: string; place?: Coordinate; stores: Nullable<Store[]> }) => {
      const {
        query,
        stores,
        place: { latitude: lat, longitude: lng } = {
          latitude: undefined,
          longitude: undefined,
        },
      } = result;
      const searchQuery = query?.trim();

      if (searchQuery) {
        lastSearchQueryRef.current = searchQuery;

        pushDataLayer({
          [DataLayerData.event]: EventsNames.storeLocatorSearch,
          [DataLayerData.siteCountry]: countryCode,
          [DataLayerData.type]: EventTypes.storeLocator,
          [DataLayerData.pageCategory]: "store_locator",
          [DataLayerData.loggedInStatus]: isUserAuthenticated,
          [DataLayerData.storeSearch]: searchQuery,
        });
      }

      if (lat && lng) {
        setFoundStores(stores);
      } else {
        setFoundStores(undefined);
      }
    },
    [countryCode, isUserAuthenticated],
  );

  const handleStoreSelect = useCallback(
    (store: Store | undefined) => setSelected(store),
    [],
  );

  const handleRedirectToStoreDetailsPage = (store: Store | undefined) => {
    pushDataLayer({
      [DataLayerData.event]: EventsNames.storeLocatorStoreSelected,
      [DataLayerData.siteCountry]: countryCode,
      [DataLayerData.type]: EventTypes.storeLocator,
      [DataLayerData.pageCategory]: "store_locator",
      [DataLayerData.loggedInStatus]: isUserAuthenticated,
      [DataLayerData.storeSearch]: lastSearchQueryRef.current,
      [DataLayerData.storeLocatorStoreSelected]: store?.geomodifier ?? undefined,
    });
  };

  useEffect(() => {
    if (stores || foundStores) {
      setSelected(undefined);
    }
  }, [stores, foundStores]);

  const seoMetaTagsProps = useMemo(
    () => ({
      seoMetaTitle: { extraWord: startCase(cityParam as string) },
      seoUrlCanonical: { pageDomain: urlPrefix },
    }),
    [cityParam, urlPrefix],
  );
  const breadcrumbsData: BreadcrumbsItem[] = useMemo(
    () => [{ title: countryName, urlSlug: Routes.STORES_SEARCH.slice(1) }],
    [countryName],
  );
  const breadcrumbJsonLd = useMemo(() => {
    return getJsonLdForBreadcrumbs(breadcrumbsData, locale);
  }, [breadcrumbsData, locale]);

  const bloomreachData = useMemo(() => {
    return {
      pType: BloomreachPageType.other,
      title: `${translations?.title} | ${dictionary?.[DictionaryKeys.BrandName]}`,
      user_id: account ? account.uid : "",
    };
  }, [account, dictionary, translations?.title]);

  useEffect(() => {
    initBloomreachPixel(bloomreachData, locale, previousPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    pushDataLayer({
      [DataLayerData.event]: EventsNames.storeLocatorOpen,
      [DataLayerData.siteCountry]: countryCode,
      [DataLayerData.type]: EventTypes.storeLocator,
      [DataLayerData.pageCategory]: "store_locator",
      [DataLayerData.loggedInStatus]: isUserAuthenticated,
    });
  }, [countryCode, isUserAuthenticated]);

  return (
    <StoreLocatorPageContainer
      isSearchControlAvailable={isSearchControlAvailable}
      {...getTestAutomationProps(testAutomationId)}
    >
      {seo && <SeoMetaTags {...seo} extraProps={seoMetaTagsProps} />}
      {!isSearchControlAvailable && (
        <>
          <Breadcrumbs className={STORE_LOCATOR_PAGE_CLASSES.breadcrumbs}>
            <CombinedLink
              href={Routes.STORES_SEARCH}
              {...getTestAutomationProps("title")}
            >
              {countryName}
            </CombinedLink>
          </Breadcrumbs>
          <JsonLd data={breadcrumbJsonLd} />
        </>
      )}
      {isSearchControlAvailable ? (
        <Typography
          variant="h1Medium"
          className={STORE_LOCATOR_PAGE_CLASSES.header}
          {...getTestAutomationProps("title")}
        >
          {translations?.title}
        </Typography>
      ) : (
        <Typography
          variant="h3"
          component="h1"
          className={STORE_LOCATOR_PAGE_CLASSES.header}
          {...getTestAutomationProps("title")}
        >
          {`${translations?.storeListTitle ?? ""} ${cityName}`}
        </Typography>
      )}
      <Grid container>
        <Grid item xs={12} md={4}>
          {isSearchControlAvailable && (
            <MapWrapper>
              <SearchField
                country={country ?? undefined}
                onSearch={handleSearch}
                translations={translations ?? {}}
                {...getTestAutomationProps("search-panel")}
              />
            </MapWrapper>
          )}
          {isStoresViewAvailable ? (
            <StoresList
              selected={selected}
              onStoreSelect={handleStoreSelect}
              onRedirectToStoreDetailsPage={handleRedirectToStoreDetailsPage}
              country={country ?? undefined}
              stores={preparedStores}
              translations={translations}
            />
          ) : (
            <CitiesList translations={translations} cities={cities} />
          )}
        </Grid>
        <Grid item xs={false} md={8} className={STORE_LOCATOR_PAGE_CLASSES.map}>
          <MapWrapper>
            <Map
              zoom={zoom}
              selected={selected}
              onStoreSelect={handleStoreSelect}
              onZoom={handleZoom}
              stores={preparedStores}
              center={coordinates}
              gestureHandling="cooperative"
              disableDefaultUI={true}
              zoomControl={true}
              clickableIcons={false}
            />
          </MapWrapper>
        </Grid>
      </Grid>
    </StoreLocatorPageContainer>
  );
};
