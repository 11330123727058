/* 
  Attention!
  Please, do not change it on your own - this function should be synched with the same function on BFF side
*/
export const encodeStoreAddress = (
  storeAddress: string | null | undefined,
): string => {
  if (!storeAddress) {
    return "";
  }
  const result = storeAddress
    // 1. Replace spaces ` ` and underscores _ with dashes -.
    ?.replace(/[\s_]+/g, "-")
    // 2. All lowercase.
    .toLowerCase()
    // 3. Replace '&' with '-and-'.
    .replace(/&/gi, "-and-")
    // 4. Remove accents and diacritics.
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    // Leave only 0-9, a-z, dashes.
    .replace(/[^a-zA-Z0-9-]/gi, "")
    // 5. Replace subsequent dashes with a single one.
    .replace(/[-]+/g, "-")
    // 6. Remove dashes from string start and end if any (trim).
    .replace(/^-/, "")
    .replace(/-$/, "");

  return result;
};
